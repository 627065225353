// Migrated

const MUST_BE_UNIQUE_CATEGORY_IDS = [1]

export const useSearchWidgetsStore = defineStore('searchWidgets', {
  state: () => ({
    destinations: {
      stagedDestinations: [],
      // NOTE Theoretically stagedCategory could be computed from stagedDestinations (i.e. a getter), but I suspect stagedCategory might need to be state since it is not 100% clear whether two categories can have the same visible destinations
      stagedCategories: [],
    },
    stagedAirport: null,
    activeSearch: null,
  }),
  getters: {
    stagedDestinationsForAirport (state) {
      const charterPackagesStore = useCharterPackagesStore()

      const airportDestinationIds = charterPackagesStore.airportDestinationIds
      if (!airportDestinationIds.length) {
        return state.destinations.stagedDestinations
      }
      return state.destinations.stagedDestinations.filter(d => airportDestinationIds.includes(d?.destination_id))
    },

    stagedDestinationsL1 (state) {
      const charterPackagesStore = useCharterPackagesStore()

      const allVisibleDestinationsL1 = charterPackagesStore.visibleDestinationsL1

      return allVisibleDestinationsL1.filter((destL1) => {
        const countryDestinations = charterPackagesStore.visibleDestinations.filter(d => d?.country === destL1)
        const countryStagedDestinations = state.destinations.stagedDestinations.filter(d => d?.country === destL1)

        return countryDestinations.length === countryStagedDestinations.length
      })
    },

    stagedDestinationsL1ForAirport (state) {
      const charterPackagesStore = useCharterPackagesStore()

      const allVisibleDestinationsL1 = charterPackagesStore.visibleDestinationsL1
      const airportDestinationIds = charterPackagesStore.airportDestinationIds

      if (!airportDestinationIds.length) {
        return this.stagedDestinationsL1
      }

      const visibleDestinations = charterPackagesStore.visibleDestinations
      const airportDestinations = visibleDestinations.filter(d => airportDestinationIds.includes(d?.destination_id))

      return allVisibleDestinationsL1.filter((destL1) => {
        const airportCountryDestinationIds = airportDestinations.filter(d => d?.country === destL1).map(d => d?.destination_id)
        const countryStagedDestinationIds = state.destinations.stagedDestinations.filter(d => d?.country === destL1).map(d => d?.destination_id)

        return airportCountryDestinationIds.length > 0 && airportCountryDestinationIds.every(id => countryStagedDestinationIds.includes(id))
      })
    },

    stagedCategoriesIds: (state) => {
      return (state.destinations.stagedCategories || []).map(({ id }) => id)
    },
  },
  actions: {
    //old mutations
    SET_STAGED_DESTINATIONS (destinations) {
      this.destinations.stagedDestinations = destinations
    },

    ADD_STAGED_DESTINATIONS (data) {
      const newData = Array.isArray(data) ? data : [data]
      const existingIds = new Set(this.destinations.stagedDestinations.map(destination => destination?.destination_id))
      const uniqueData = newData.filter(destination => !existingIds.has(destination?.destination_id))

      this.destinations.stagedDestinations = [...this.destinations.stagedDestinations, ...uniqueData]
    },

    REMOVE_STAGED_DESTINATIONS (data) {
      if (Array.isArray(data)) {
        this.destinations.stagedDestinations = this.destinations.stagedDestinations.filter(d => !data.includes(d?.destination_id))
      } else {
        this.destinations.stagedDestinations = this.destinations.stagedDestinations.filter(d => d?.destination_id !== data?.destination_id)
      }
    },

    SET_STAGED_CATEGORIES (categories) {
      this.destinations.stagedCategories = categories
    },

    TOGGLE_STAGED_CATEGORY (category) {
      const isCategoryToggled = this.destinations.stagedCategories.some(({ id }) => id === category.id)
      if (isCategoryToggled) {
        this.destinations.stagedCategories = this.destinations.stagedCategories.filter(({ id }) => id !== category.id)
      } else {
        this.destinations.stagedCategories = [
          ...this.destinations.stagedCategories,
          category,
        ]
      }
    },

    SET_STAGED_AIRPORT (data) {
      this.stagedAirport = data
    },

    SET_ACTIVE_SEARCH (data) {
      this.activeSearch = data
    },

    handleDestination (destination) {
      const charterPackagesStore = useCharterPackagesStore()

      charterPackagesStore.SET_IS_DIRTY(true)
      this.SET_STAGED_CATEGORIES([])

      const destinationIndex = this.destinations.stagedDestinations.findIndex(s => s?.destination_id === destination?.destination_id)

      if (destinationIndex > -1) {
        this.REMOVE_STAGED_DESTINATIONS(destination)
      } else {
        this.ADD_STAGED_DESTINATIONS(destination)
      }
    },

    handleDestinationL1 (name) {
      const charterPackagesStore = useCharterPackagesStore()

      charterPackagesStore.SET_IS_DIRTY(true)
      this.SET_STAGED_CATEGORIES([])

      if (this.stagedDestinationsL1ForAirport.includes(name)) {
        this.SET_STAGED_DESTINATIONS(this.destinations.stagedDestinations.filter(d => d?.country !== name))
        return
      }

      this.ADD_STAGED_DESTINATIONS(charterPackagesStore.destinations.filter(d => d?.country === name && d.visible))
    },

    toggleCategory (category) {
      const charterPackagesStore = useCharterPackagesStore()

      charterPackagesStore.SET_IS_DIRTY(true)

      if (typeof category !== 'object') {
        category = charterPackagesStore.categories.find(c => parseInt(c.id) === parseInt(category))
      }

      if (!category) {
        return
      }

      if (!this.stagedCategoriesIds.includes(category.id)) {
        if (MUST_BE_UNIQUE_CATEGORY_IDS.includes(category.id)) {
          this.handleCategories([category])
          return
        } else {
          const stagedCategories = this.destinations.stagedCategories || []
          const nonUniqueCategories = stagedCategories.filter(({ id }) => !MUST_BE_UNIQUE_CATEGORY_IDS.includes(id))
          if (nonUniqueCategories.length !== stagedCategories.length) {
            this.handleCategories([...nonUniqueCategories, category])
            return
          }
        }
      }

      let categories = []
      const isCategoryToggled = this.destinations.stagedCategories.some(({ id }) => id === category.id)
      if (isCategoryToggled) {
        categories = this.destinations.stagedCategories.filter(({ id }) => id !== category.id)
      } else {
        categories = [
          ...this.destinations.stagedCategories,
          category,
        ]
      }

      this.handleCategories(categories)
    },

    handleCategories (categories) {
      const charterPackagesStore = useCharterPackagesStore()

      charterPackagesStore.SET_IS_DIRTY(true)

      this.SET_STAGED_CATEGORIES(categories)
      this.SET_STAGED_DESTINATIONS(
        charterPackagesStore.visibleDestinations.filter(d => d.categories.some(category => this.stagedCategoriesIds.includes(parseInt(category))) && d.visible)
      )
    },

    async prefillDestinations (prefill) {
      const charterPackagesStore = useCharterPackagesStore()

      if (!charterPackagesStore.destinations || charterPackagesStore.destinations.length === 0) {
        await charterPackagesStore.getDestinations()
      }

      this.SET_STAGED_DESTINATIONS([])
      this.SET_STAGED_CATEGORIES([])

      if (prefill) {
        if (prefill.selectedDestinations?.length) {
          this.SET_STAGED_DESTINATIONS(prefill.selectedDestinations)
        } else if (prefill.selectedDestinationsL1?.length) {
          this.SET_STAGED_DESTINATIONS([])
        }

        if (prefill.selectedDestinationsL1?.length) {
          prefill.selectedDestinationsL1.forEach((d) => {
            this.handleDestinationL1(d)
          })
        }

        if (prefill.selectedCategories?.length) {
          const categoryObjects = (prefill.selectedCategories || [])
            .map(categoryId => charterPackagesStore.categories.find(({ id }) => id === categoryId))
            .filter(Boolean)

          this.handleCategories(categoryObjects)
        } else if (!this.destinations.stagedDestinations.length) {
          this.toggleCategory(charterPackagesStore.categoriesForAirport[0])
        }

        charterPackagesStore.SET_IS_DIRTY(false)
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchWidgetsStore, import.meta.hot))
}